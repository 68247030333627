import * as React from "react";
import { FC } from 'react';
import {
    EditButton,
    List,
    Datagrid,
    TextField,
    DateField,
    ListProps,
    usePermissions
} from 'react-admin';


const SurveyList: FC<ListProps> = props => {
    
  const { loaded, permissions } = usePermissions();

  if (!loaded) {
    return null; // Đợi quyền truy cập được tải
  }

  const isAdmin = permissions.includes('ROLE_ADMIN');
  const isSaleAdmin = permissions.includes('ROLE_SALES_ADMIN');
  const isSurvey = permissions.includes('ROLE_SURVEY');
    return (
        <List title="All Survey" {...props} hasCreate = {isAdmin||isSaleAdmin||isSurvey} sort={{ field: 'id', order: 'DESC' }}>
            <Datagrid>
                <TextField source="id"/>
                <TextField source="code"/>
                <TextField source="name"/>
                <TextField source="officialUrl"/>
                <DateField source="created" showTime />
                {(isAdmin||isSaleAdmin) && <EditButton />}
            </Datagrid>
        </List>
    );
};

export default SurveyList;